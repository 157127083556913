import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const trackAndTraceFilters = localStorage.getItem('trackAndTraceFilters')
  ? JSON.parse(localStorage.getItem('trackAndTraceFilters'))
  : {
      fleet: null,
      earlyLate: [-6, 6],
      dwell: [0, 24],
      deadhead: [50, 1000],
      ptaLocation: null,
      ptaLocationRadius: null,
      currentLocation: null,
      currentLocationRadius: null,
    };

const total =
  Number(trackAndTraceFilters.fleet ? 1 : 0) +
  Number(trackAndTraceFilters.earlyLate[0] !== -6 || trackAndTraceFilters.earlyLate[1] !== 6 ? 1 : 0) +
  Number(trackAndTraceFilters.dwell[0] || trackAndTraceFilters.dwell[1] !== 24 ? 1 : 0) +
  Number(trackAndTraceFilters.deadhead[0] !== 50 || trackAndTraceFilters.deadhead[1] !== 1000 ? 1 : 0) +
  Number(trackAndTraceFilters.ptaLocation ? 1 : 0) +
  Number(trackAndTraceFilters.ptaLocationRadius ? 1 : 0) +
  Number(trackAndTraceFilters.currentLocation ? 1 : 0) +
  Number(trackAndTraceFilters.currentLocationRadius ? 1 : 0);

const initialState = Object.freeze({
  trackAndTraceFilters,
  total,
});

const trackAndTraceSlice = createSlice({
  name: 'trackAndTrace',
  initialState,
  reducers: {
    setItem(state, action) {
      const newItem = action.payload;

      state.trackAndTraceFilters = newItem;

      state.total =
        Number(newItem.fleet ? 1 : 0) +
        Number(newItem.earlyLate[0] !== -6 || newItem.earlyLate[1] !== 6 ? 1 : 0) +
        Number(newItem.dwell[1] !== 24 || newItem.dwell[0] ? 1 : 0) +
        Number(newItem.deadhead[0] !== 50 || newItem.deadhead[1] !== 1000 ? 1 : 0) +
        Number(newItem.ptaLocation ? 1 : 0) +
        Number(newItem.ptaLocationRadius ? 1 : 0) +
        Number(newItem.currentLocation ? 1 : 0) +
        Number(newItem.currentLocationRadius ? 1 : 0);

      localStorage.setItem('trackAndTraceFilters', JSON.stringify(newItem));
    },
  },
});

export const trackAndTraceActions = trackAndTraceSlice.actions;
export default trackAndTraceSlice;
