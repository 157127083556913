import React, { useCallback, useState } from 'react';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, SvgIcon } from '@mui/material';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as Logo } from '../../assets/logos/enru-small.svg';
import { ReactComponent as FleetIconSvg } from '../../assets/icons/mfleet.svg';
import { ReactComponent as FreightsIconSvg } from '../../assets/icons/mfreights.svg';
import { ReactComponent as ExternalIconSvg } from '../../assets/icons/mexternal.svg';
import { ReactComponent as TrackAndTraceIconSvg } from '../../assets/icons/mtrackandtrace.svg';
import { ReactComponent as TimelineIconSvg } from '../../assets/icons/mtimeline.svg';
import { useAuth } from '../../hooks/useAuth';
import { CustomDialog } from '../UI/CustomDialog';
import { msalInstance } from '../../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/HelpOutline';
import AppsIcon from '@mui/icons-material/Apps';
import UsersIcon from '@mui/icons-material/People';
import { getEnv } from '../../utils';
import PropTypes from 'prop-types';

function FleetIcon(props) {
  return <SvgIcon component={FleetIconSvg} viewBox="0 0 34 24" {...props} />;
}

function FreightsIcon(props) {
  return <SvgIcon component={FreightsIconSvg} viewBox="0 0 30 30" {...props} />;
}

function ExternalIcon(props) {
  return <SvgIcon component={ExternalIconSvg} viewBox="0 0 30 30" {...props} />;
}

function TrackAndTraceIcon(props) {
  return <SvgIcon component={TrackAndTraceIconSvg} viewBox="0 0 30 31" {...props} />;
}

function TimelineIcon(props) {
  return <SvgIcon component={TimelineIconSvg} viewBox="0 0 24 24" {...props} />;
}

const SideMenu = ({ handleClick }) => {
  const { getUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [logoutDialogVisibility, setLogoutDialogVisibility] = useState(false);

  const handleLogoutClick = useCallback(() => {
    setLogoutDialogVisibility(true);
  }, []);

  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };

  const handleLocation = item => {
    if (!item.path) return;
    navigate(item.path);
  };

  const getMenuItems = () => {
    const activePath = location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const menuItems = [
      {
        icon: <TrackAndTraceIcon />,
        text: 'Track & Trace',
        path: '/track-and-trace',
        testId: 'main-menu_track-and-trace_button',
      },
      {
        icon: <TimelineIcon />,
        text: 'Timeline',
        path: '/timeline',
        testId: 'main-menu_timeline_button',
      },
      {
        icon: <FleetIcon />,
        text: 'Fleet',
        path: '/',
        testId: 'main-menu_fleet_button',
      },
      {
        icon: <FreightsIcon />,
        text: 'Freight',
        path: '/?layers=0100',
        testId: 'main-menu_freight_button',
      },
      {
        icon: <ExternalIcon />,
        text: 'External Freight',
        path: '/?layers=0010',
        testId: 'main-menu_external-freight_button',
      },
      {
        icon: <TimelineIcon />,
        text: 'Combined',
        path: '/combined',
        testId: 'main-menu_combined_button',
      },
    ];

    if (getEnv() === 'development') {
      menuItems.push({ icon: <UsersIcon />, text: 'Users', path: '/users', testId: 'main-menu_users_button' });
      menuItems.push({
        icon: <AppsIcon />,
        text: 'Style Guide',
        path: '/styleguide',
        testId: 'main-menu_style-guide_button',
      });
      menuItems.push({ icon: <HelpIcon />, text: 'Documentation', path: '/docs', testId: 'main-menu_docs_button' });
    }
    return menuItems.map(item => ({
      ...item,
      // Directly add an `active` property based on the current active path
      active: checkActivePath(activePath, queryParams, item),
    }));
  };

  const checkActivePath = (activePath, queryParams, item) => {
    const itemURL = new URL(item.path, window.location.origin);
    const itemPath = itemURL.pathname;
    const itemQueryParams = new URLSearchParams(itemURL.search);

    // First, check if the paths match
    if (activePath !== itemPath) return false;

    // If the paths match, check the 'layers' query parameter if it exists
    if (queryParams.has('layers') && itemQueryParams.has('layers')) {
      return queryParams.get('layers') === itemQueryParams.get('layers');
    }

    if (itemQueryParams.has('layers') && !queryParams.has('layers')) return false;
    if (!itemQueryParams.has('layers') && queryParams.has('layers')) return false;

    // If no layers parameter is involved, but paths match, consider it active
    return true;
  };

  const goHome = () => {};

  return (
    <>
      <div className={`sidebar--menu`}>
        <div className="inner-container">
          <Paper className="side-menu">
            <div className="head">
              <IconButton className="b-home" onClick={goHome}>
                <Logo />
              </IconButton>
              <Logo />
            </div>
            <div className="inner-container">
              <List>
                {getMenuItems().map((menuItem, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      className={menuItem.active ? 'active' : ''}
                      button
                      selected={menuItem.selected}
                      onClick={() => handleLocation(menuItem)}
                      data-testid={menuItem.testId}
                    >
                      {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                      <ListItemText primary={menuItem.text} />
                    </ListItem>
                    {menuItem.subItems && (
                      <List component="div" disablePadding>
                        {menuItem.subItems.map((subItem, subIndex) => (
                          <ListItem
                            data-testid={subItem.testId}
                            selected={subItem.selected}
                            button
                            key={subIndex}
                            onClick={() => handleLocation(subItem)}
                            sx={{ pl: 7 }}
                          >
                            {subItem.text}
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </React.Fragment>
                ))}
                <Divider />
                <ListItem onClick={handleLogoutClick} className="logout" button>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Logout
                    <div className="user-info">{getUser()?.name}</div>
                  </ListItemText>
                </ListItem>
              </List>
            </div>

            <CustomDialog
              title="Logout"
              content="Would you like to logout ?"
              buttonConfirmTitle="Logout"
              open={logoutDialogVisibility}
              handleConfirm={handleLogout}
              handleCancel={() => setLogoutDialogVisibility(false)}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

SideMenu.propTypes = {
  handleClick: PropTypes.func,
};

export default SideMenu;
